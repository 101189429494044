exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-contact-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/pages/contact/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-contact-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-redirects-church-numbers-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/pages/redirects/church-numbers.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-redirects-church-numbers-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-redirects-graphs-and-penguins-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/pages/redirects/graphs-and-penguins.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-redirects-graphs-and-penguins-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-redirects-ice-cream-anagrams-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/pages/redirects/ice-cream-anagrams.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-pages-redirects-ice-cream-anagrams-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-01-graphs-and-penguins-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/posts/01-graphs-and-penguins/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-01-graphs-and-penguins-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-02-church-numbers-en-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/posts/02-church-numbers-en/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-02-church-numbers-en-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-02-church-numbers-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/posts/02-church-numbers/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-02-church-numbers-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-03-icecream-anagrams-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/Users/sophie/repos/sophiasaiada.github.io/content/posts/03-icecream-anagrams/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-users-sophie-repos-sophiasaiada-github-io-content-posts-03-icecream-anagrams-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */)
}

